@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  /* Preflight styles added manually */
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: theme('borderColor.DEFAULT', currentColor);
  }
}

@import '@crystal-eyes/meetings/styles/themes/pm-dark.css';
@import '@crystal-eyes/meetings/styles/themes/pm-classic.css';
@import '@crystal-eyes/meetings/styles/themes/pm-disc.css';

html {
  scroll-behavior: smooth;
}

body {
  background-image: var(--background-image-url);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  margin: 0 0;
  font-family: var(--font-lato);
}

.nav-areas {
  position: relative;
  width: min(100%, var(--main-content-width));
  margin: auto auto;
}

@media (max-width: 940px) {
  .nav-areas {
    position: static;
    margin: 0;
    width: 100%;
  }
}

.close {
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
